import * as actions from './actions'
import mutations from './mutations'
import * as getters from './getters'

const DeliveryStore = {
  state: {
    deliveries: [],
    countStatus: {}
  },
  getters,
  mutations,
  actions
}

export default DeliveryStore
