import axios from '../../plugins/axios'

export const listStores = ({ commit }, { params } = {}) => {
  return axios.get('store', { params })
    .then((res) => {
      commit('SET_STORES', res.data.rows)
      return res
    })
    .catch(err => err.response)
}

export const listAllStoreIdName = (_, { params } = {}) => {
  return axios.get('all-store-id-name', { params })
    .then((res) => {
      return res.data
    })
    .catch(err => err.response)
}

export const listAllStoreName = (_, { params } = {}) => {
  return axios.get('all-store', { params })
    .then((res) => {
      return res.data
    })
    .catch(err => err.response)
}

export const getStore = ({ commit }, { storeId } = {}) => {
  return axios.get(`store/${storeId}`)
    .then((res) => {
      commit('SET_STORE', res.data)
      return res
    })
    .catch(err => err.response)
}

export const getStoreCountDeliveries = ({ commit }, { storeId } = {}) => {
  return axios.get(`store/${storeId}/count-deliveries`)
    .then((res) => {
      commit('SET_STORE', res.data)
      return res
    })
    .catch(err => err.response)
}

export const updateStore = ({ commit }, { storeId, data }) => {
  return axios.put(`/store/${storeId}`, data)
    .then(res => {
      commit('SET_STORE', res.data)
      return res
    })
    .catch(err => err.response)
}

export const getStatusStore = ({ commit }) => {
  return axios.get('status')
    .then((res) => {
      commit('SET_STATUS_STORES', res.data)
      return res.data
    })
    .catch(err => err.response)
}

export const updateStatusStore = ({ commit }, { statusId, data }) => {
  return axios.put(`/status/${statusId}`, data)
    .then(res => {
      commit('SET_STATUS_STORES', res.data)
      return res.data
    })
    .catch(err => err.response)
}

export const deleteStore = (_, { storeId }) => {
  return axios.delete(`/store/${storeId}`)
    .then(res => res.data)
}
