import { render, staticRenderFns } from "./App.vue?vue&type=template&id=28ab4f6a&scoped=true&lang=pug&"
import script from "./App.vue?vue&type=script&lang=js&"
export * from "./App.vue?vue&type=script&lang=js&"
import style0 from "./App.vue?vue&type=style&index=0&id=28ab4f6a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28ab4f6a",
  null
  
)

export default component.exports