<template lang="pug">
  div
    .tabla.sombra
      b-row.mb-2
        b-col
          h3 Envíos
        b-col(md="auto")
          b-button(@click="downloadExcelDelivery({ params: backendFilters })" block) Exportar Excel
      b-form(@submit.stop.prevent="fetchData()")
        b-form-row
          b-col.mb-2(cols="12" md="3")
            b-form-input(v-model="backendFilters.search" placeholder="Buscar...")
          b-col.mb-2(cols="12" md="3")
            b-form-select(v-model="backendFilters.status")
              b-form-select-option(:value="null") Selecciona un Estado
              b-form-select-option(:value="'approved'") Aprobados
              b-form-select-option(:value="'assigned'") Asignados
              b-form-select-option(:value="'in-transit'") En Tránsito
              b-form-select-option(:value="'partial'") Parciales
              b-form-select-option(:value="'rejected'") Rechazados
          b-col.mb-2(cols="12" md="3" lg="2" style="max-width: fit-content;")
            //- b-form-checkbox(
            //-   switch
            //-   size="lg"
            //-   :v-model="backendFilters.isByRange"
            //-   @change="toggleRange"
            //- ) Buscar <br/> por rango
            div
              b-button.toggle-btn(
                :style="styleDateBtn"
                style="border-radius: 5px 0px 0px 5px;"
                @click="toggleBtn('date')"
              ) Por fecha
              b-button.toggle-btn(
                :style="styleRangeBtn"
                style="border-radius: 0px 5px 5px 0px;"
                @click="toggleBtn('range')"
              ) Por rango
          b-col.mb-2(v-if="backendFilters.isByRange" cols="12" md="3" lg="2" style="max-width: fit-content")
            b-form-datepicker(
              label-no-date-selected="Desde..."
              v-model="backendFilters.startDate"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              :min="backendFilters.minStartDate"
              :max="backendFilters.date"
              @input="setDateRange"
              reset-button
              style="width: fit-content"
            )
          b-col.mb-2(v-if="backendFilters.isByRange" cols="12" md="3" lg="2" style="max-width: fit-content")
            b-form-datepicker(
              label-no-date-selected="Hasta..."
              v-model="backendFilters.endDate"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              :min="backendFilters.startDate"
              :max="backendFilters.maxEndDate || backendFilters.date"
              reset-button
              style="width: fit-content"
            )
          b-col.mb-2(v-if="!backendFilters.isByRange" cols="12" md="3" lg="2")
            b-form-datepicker(
              label-no-date-selected="sin fecha"
              v-model="backendFilters.date"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              reset-button
            )
          b-col(cols="auto")
            b-btn(type="submit" variant="primary") Filtrar
      b-table(:fields="fields" :items="deliveries" responsive)
        template(#cell(date)="{value}")
          div(style="min-width: 80px") {{ value | moment('DD/MM/YYYY') }}
        template(#cell(actions)="{item}")
          b-button(v-if="item.pdfUrl" target="_blank" variant="ligth" :href="item.pdfUrl" size="sm")
            .material-icons-outlined file_open
        template(#cell(status)="{value}")
          StatusBadge(:status="value")
      .d-flex.justify-content-center
        b-pagination.m-auto(
          @change="backendFilters.page = $event, fetchData()"
          :total-rows="countRows"
          :per-page="backendFilters.size"
          aria-controls="my-table"
        )
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import DeliveryStateCards from './DeliveryStateCards'
import StatusBadge from '../common/StatusBadge'

export default {
  props: {
    deliveryList: Array
  },
  components: {
    DeliveryStateCards,
    StatusBadge
  },
  data () {
    return {
      activeBtn: 'color: #fff; background-color: #3276b1; border-color: #285e8e; transform: translateY(0.8px);',
      inactiveBtn: 'color: #333; background-color: #fff; border-color: #ccc;',
      styleDateBtn: 'color: #fff; background-color: #3276b1; border-color: #285e8e; transform: translateY(0.8px);',
      styleRangeBtn: 'color: #333; background-color: #fff; border-color: #ccc;',
      backendFilters: {
        search: '',
        page: 1,
        size: 30,
        date: new Date().toISOString().slice(0, 10),
        startDate: null,
        endDate: null,
        status: null,
        isByRange: false,
        minStartDate: null,
        maxStartDate: null,
        maxEndDate: null
      },
      countRows: 0
    }
  },
  methods: {
    ...mapActions(['listDelivery', 'downloadExcelDelivery']),
    async fetchData (sync = false) {
      const params = { ...this.backendFilters, sync }
      if (!params.date) {
        delete params.date
      }
      const response = await this.listDelivery({ params })
      if (response) {
        this.countRows = response.data.count
      }
    },
    toggleRange () {
      this.backendFilters.isByRange = !this.backendFilters.isByRange

      if (!this.backendFilters.isByRange) {
        this.backendFilters.startDate = null
        this.backendFilters.endDate = null
      } else {
        this.backendFilters.date = new Date().toISOString().slice(0, 10)
      }
    },
    setDateRange (value) {
      this.backendFilters.endDate = null
      if (!value) return

      const date = new Date(value)
      const today = new Date()
      const maxDate = new Date(date.setMonth(date.getMonth() + 1))

      this.backendFilters.minEndDate = value
      if (maxDate.getTime() > today.getTime()) {
        this.backendFilters.maxEndDate = this.backendFilters.date
      } else {
        this.backendFilters.maxEndDate = maxDate.toISOString().slice(0, 10)
      }

      // console.log(this.backendFilters, 'filtros del back')
    },
    toggleBtn (btn) {
      if (btn === 'date') {
        this.styleDateBtn = this.activeBtn
        this.styleRangeBtn = this.inactiveBtn
        if (this.backendFilters.isByRange) {
          this.toggleRange()
        }
      } else {
        this.styleRangeBtn = this.activeBtn
        this.styleDateBtn = this.inactiveBtn
        if (!this.backendFilters.isByRange) {
          this.toggleRange()
        }
      }
    }
  },
  computed: {
    ...mapGetters(['deliveries', 'store', 'user']),
    fields () {
      const fields = [
        { label: 'Fecha', key: 'date' },
        { label: 'Código', key: 'drivInCode' },
        { label: 'Destinatario', key: 'finalCustomer' },
        { label: 'Teléfono', key: 'contactPhone' },
        { label: 'Dirección', key: 'address' },
        { label: 'Comuna', key: 'commune' },
        { label: 'Observaciones', key: 'comment' },
        { label: 'Estado', key: 'status' },
        { label: 'Detalle', key: 'actions' }
      ]
      if (this.user.role === 'admin') {
        fields.splice(2, 0, { label: 'Proveedor', key: '_empty' })
        fields.splice(3, 0, { label: 'Tienda', key: 'customerName' })
      }
      return fields
    }
  },
  created () {
    this.fetchData()
  },
  watch: {
    'backendFilters.status': {
      handler (value) {
        this.backendFilters.page = 1
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #869099;
  outline: 0;
  box-shadow: 0 0 0 0.2rem #ced4da;
}
/* .toggle-btn {
    transition: color 0.07s ease-in-out, background-color 0.07s ease-in-out, border-color 0.07s ease-in-out, box-shadow 0.07s ease-in-out !important
}
.toggle-btn:focus {
    box-shadow: inset 1px 2px 5px #1b3f63 !important;
    transition: color 0.07s ease-in-out, background-color 0.07s ease-in-out, border-color 0.07s ease-in-out, box-shadow 0.15s ease-in-out !important

} */
</style>
