import Vue from 'vue'
import App from './App.vue'
import VueMoment from 'vue-moment'
import moment from 'moment'
import momentTimeZone from 'moment-timezone'
import './registerServiceWorker'
import router from './router'
import store from './store'
import './plugins/bootstrap-vue'
import './style/global.scss'
import Vuelidate from 'vuelidate'

Vue.config.productionTip = false

Vue.use(Vuelidate)
Vue.use(VueMoment, { moment })
momentTimeZone.tz.setDefault('America/Santiago')

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
